<template>
  <div class="microphone-container"  @click="handleClick">
    <!-- 动态音量图标 -->
    <i 
       style="position: relative;"
      :style="{fontSize:size+'px'}"
      :class="[micOpen ?'':'disableCameraOpen','el-icon-microphone']" 
     
    >
    <div v-if="micOpen" class="volume-bar-container">
      <div 
        class="volume-bar" 
        :style="{ height: `${volumePercentage}%` }"
      ></div>
    </div>
  </i>
  
  </div>
</template>

<script>
export default {
  name: "VolumeMicrophone",
  props: {
    size: {
      type: Number,
      default:32
    },
      micOpen: {
      type: Boolean,
      default: true,
    },
    // 接收外部传入的音量值（0-100）
    volume: {
      type: Number,
      default:0
    },
  },
  computed: {
    // 音量百分比用于音量条高度
    volumePercentage() {
      return Math.max(0, Math.min(100, this.volume));
    },
  },
  methods: {
    handleClick() {
      console.log(212)
      this.$emit("closeMic",'mic'); // 触发点击事件
    },
  },
};
</script>

<style scoped>

.disableCameraOpen:after{
  position: absolute;
  content: '';
 width: 10%;
 height: 100%;
 top: 0%;
 left: 40%;
 background: red;
 transform: rotate(45deg);
}
.el-progress-bar{
  position: absolute;
}
.microphone-container {
  position: relative;
  text-align: center;
  display: inline-block;
  margin-left: 12px;
}

/* 麦克风图标 */
.el-icon-microphone {
  transition: color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

/* 音量条容器 */
.volume-bar-container {
  width: 25%;
  height:50%;
  position: absolute;
  top:10%;
  left: 37%;
  background-color: transparent;
  border-radius:35%;
  overflow: hidden;
  transform:rotate(-180deg);
}

/* 音量条 */
.volume-bar {
  width: 80%;
  margin: 0 auto;
  background-color: rgb(0, 255, 0);
  transition: height 0.2s ease;
}
</style>
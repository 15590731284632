<template>
  <div
    class="copyright fm color9 tc"
    :class="[notLogin?'ft16':'ft14']"
    :style="{ color: notLogin ? '#B1B5B9' : '#999999' }"
  >
    <!-- <p class="p1">
      <i></i>
      <span>400-000-8255转2</span>
    </p> -->
    <p class="p2">
      Copyright © 2019-至今 zhuoedu.com，All Rights Reserved
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        :style="{ color: notLogin ? '#B1B5B9' : '#999999',textDecoration:'none' }"
      > 浙ICP备20019680-2号</a>
    </p>
    <p class="p3" v-if="!notLogin">
      壹卓科技 版权所有
    </p>
  </div>
</template>

<script>
export default {
  props: {
    notLogin: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="less">
.copyright {
  // margin: 0.54px auto 0.4px;
  letter-spacing: 1px;
  i {
    display: inline-block;
    width: 0.26px;
    height: 0.26px;
    margin-right: 0.1px;
    // background: url('../../../assets/tel-icon.png') no-repeat center top;
    // background-size: 100%;
    position: relative;
    top: 0.01px;
  }
  .p1 {
    height: 0.3px;
    line-height: 0.3px;
    margin-bottom: 0.16px;
    display: flex;
    justify-content: center;
  }
  .p3 {
    margin-top: 10px;
  }
}
</style>

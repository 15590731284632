<template>
  <el-container style="height:100%;"  v-on:click.native="dropShowFalse">
    <el-header>
      <div class="logo">
        <img v-if="logoUrl" :src="logoUrl" style="width:100%;height:100%;" />
        <span v-else>卓学云</span>
      </div>
      <div class="account_block pointer" @click.stop="showDropMenu">
        <div class="avatar default_bg">
          <img v-if="avatar" :src="avatar" style="display:block;width:100%;">
        </div>
        <span>
          hello,{{user.Name}}
        </span>
        <span
          class="el-icon-arrow-down"
          style="line-height:65px;margin-left:0.10px;"
        ></span>
        <p class="hover_show_name ft14 color3 bg_white">{{user.AgentName}}</p>
        <div ref="fropShow" v-show="dropShow" class="drop_menu bg_white theme_color">
          <div class="company_box">
            <p
              class="p1 ft18 color3"
              @mouseenter="mouseEnter"
              @mouseleave="mouseLeave"
              :style="{transition:`all ${moveLeftNum>20?moveLeftNum*0.6/20:1.5}s`,textIndent:moveLeft}"
            >{{user.AgentName}}</p>
            <p class="p2 ft16 color6">机构ID：{{user.Company}}</p>
          </div>
          <ul>
            <li @click="editAccount">
              <span class="iconfont icon-gerenziliao icon_right5"></span>个人资料
            </li>
            <li v-if="$sysType==1" @click="accountCertify">
              <span
                class="iconfont icon-renzheng1 icon_right5"
                style="font-size:17px;margin-left:-1px;"
              ></span>账号认证
            </li>
            <li @click="changePass">
              <span class="iconfont icon-mima1 icon_right5"></span>修改密码
            </li>
          </ul>

          <el-button class="quit_btn" @click="logout">退出</el-button>
        </div>

      </div>
    </el-header>
    <el-container style="height:calc(100% - 65px - 30px - 3px);">
      <el-aside>
        <Menu @menuSelect="menuSelect" />
      </el-aside>
      <el-main class="vertival_flex">
        <!-- <div class="wh100" style="overflow-y:auto;">
          <router-view />
        </div> -->
        <div v-if="!isLeftMenu($route.path)" style="width:100%;">
          <CrumbNav :crumbList="crumbList" />
        </div>
        <div style="height: 100%;">
          <keep-alive :max="3">
            <router-view :key="$route.fullPath" v-if="keepAlive" />
          </keep-alive>
          <router-view :key="$route.fullPath" v-if="!keepAlive" />

          <!-- <keep-alive>
            <router-view :key="$route.fullPath" />
          </keep-alive> -->
        </div>


      </el-main>
    </el-container>
    <el-footer>
      <el-row style="height:100%;" type="flex" justify="center" align="middle">
        <FooterCopyright :notLogin="true" />
      </el-row>
    </el-footer>
  </el-container>

</template>
<script>
import { Bus } from '@/utils/eventBus';
import {mapGetters, mapActions} from 'vuex';
import Menu from './components/Menu.vue';
import filterCrumbList from '@/router/crumbList.js';
export default {
  components:{
    Menu
  },
  data() {
    return {
      dropShow:false,
      avatar:'',
      user:{},
      logoUrl:'',
      moveLeft:'',
      moveLeftNum:0,
      crumbList:[],
      isLeftMenu:this.$utils.isLeftMenu,
    };
  },
  computed:{
    ...mapGetters(['getUserInfo','getCrumbNavList']),
    keepAlive() {
      console.log(this.$route.meta)
      return this.$route.meta.keepAlive
    }
  },
  watch:{
    '$route'(n,o){

      // console.log(n,'nnn',this.isLeftMenu(path))
      const {path,name} = n;
      let lastPath = o.path;
      // this.route
      if(n.path=='/liveStudio'){
        document.getElementById('app').style.minHeight = '600px'
      }else{
        document.getElementById('app').style.minHeight = '600px'
        // document.getElementById('app').style.minHeight = '800px'
      }

      this.handleCrumbList(path,lastPath);
    }
  },

  mounted(){
    this.user = this.getUserInfo;
    let path = this.$route.path;
    this.handleCrumbList(path);
    if(path=='/dashboard'){
      this.checkOutOfDate();
    }
    this.getAvatar(this.user.Picture);
    this.getLogoPic(this.user.AgentLogo);
    Bus.$on('logoSetted',(e)=>{console.log(e,'eee');this.updateLocalLogo(e)})
    Bus.$on('avatarSetted',(e)=>{console.log(e,'eee');this.updateLocalAvatar(e)})

  },
  methods:{
    ...mapActions(['delUserInfo','storeUserInfo','delBaseMapData']),

    /** 处理导航栏的内容显示 */
    handleCrumbList(path,lastPath){
      //console.log('handleCrumbList from:', path, ' to:', lastPath);
      if(this.isLeftMenu(path)){
        console.log('不用展示面包屑')
      }else{
        this.crumbList = filterCrumbList(path,lastPath);
      }
    },

    menuSelect(indexPath){
      console.log('xuan',indexPath);
    },
    showDropMenu(){
      this.dropShow = !this.dropShow;
      // if(this.currentStuInfo.StuName){
      //   this.dropShow = !this.dropShow;
      // }
    },
    mouseEnter(){
      // 计算公司名字  和 外壳宽度，得到 text-indent的值  然后配合 transition
      let indentNum;
      let nameWidth = this.user.AgentName.length*18;
      if(nameWidth>160){
        indentNum = nameWidth -160;
      }else{
        indentNum = 0
      }
      this.moveLeft = '-'+indentNum+'px';
      this.moveLeftNum = indentNum;
    },
    mouseLeave(){
      this.moveLeft = 0;
      this.moveLeftNum = 0;
    },

    getLogoPic(picName){
      if(picName){
        let apiName = this.$sysType==1?'getPicByName':'getPicByName2'
        this.$api[apiName]('USR_RES_AGTPIC',picName).then(res=>{
          // console.log(res,'res1')
            this.logoUrl = res;
            // Bus.$off('logoSetted')
            // this.$set(this.formData,'fileList'+j,fileList)
        }).catch(err=>{
          console.log('err1','i999');
        })
      }
    },

    updateLocalLogo(e){
      // console.log(e,'44444333e',this.getUserInfo)
      const {AgentLogo,AgentName} = e;
      this.getLogoPic(AgentLogo);
      // 把修改的名字同时要存入本地缓存，这样刷新页面的时候不会异常
      let obj = this.getUserInfo;
      obj.AgentLogo = AgentLogo;
      obj.AgentName = AgentName
      this.storeUserInfo(obj);
    },

    updateLocalAvatar(e){
      const {Picture} = e;
      this.getAvatar(Picture);
      // 把修改的名字同时要存入本地缓存，这样刷新页面的时候不会异常
      let obj = this.getUserInfo;
      obj.Picture = Picture;
      this.storeUserInfo(obj);
    },

    // 进入首页 暂时随便调一个接口 检测系统登录超时
    checkOutOfDate(){
      const apiName='AgentBaseData';
      this.$api[apiName]().then(res=>{
      }).catch(err=>{
        console.log(err)
      });
    },

    dropShowFalse(e) {
      this.dropShow = false;
    },

    editAccount(){
      this.$router.push('/editAccount');
    },

    accountCertify(){
      this.$router.push('/accountCertify');
    },

    switchStu(){},
    changePass(){
      this.$router.push('/editPass');
    },
    logout(){
      this.$api.quit().then(res=>{
        this.$tipOk(res.ErrMsg);
        this.delUserInfo();
        setTimeout(()=>{
          // window.location.href = location.href.split('#')[0]+'/#/login'
          window.location.href = this.$reloadLoginUrl;
        },800)

      }).catch(err=>{})

    },

    getAvatar(picName){
      let apiName = this.$sysType==1?'getPicByName':'getPicByName2'
      this.$api[apiName]('USR_RES_TCHPIC',picName).then(res=>{
        this.avatar = res;
      }).catch(err=>{
        console.log('err1','i999');
      })
    },
  }
};
</script>
<style lang="less" scoped>
.el-header{
  background: white;
  height: 85px!important;
  padding:0!important;
}
.el-aside{
  background:#292A3C;
  color:white;
  width: 220px!important;
}
.el-main{
  background-color:#f5f6f8;
  padding: 10px 10px;
  height: 100%;
  overflow-y: auto;
}
.vertival_flex{
  display: flex;
  flex-direction:column;
  flex-wrap: nowrap;
}
.el-footer{
  background:#202020;
  color:white;
  height: 36px!important;
}
.icon_right5{
  margin-right:5px;
}
.logo{
  float:left;
  width: 220px;
  height: 100%;
  background-color: #333446;
  color:white;
  text-align: center;
  line-height: 85px;
}
.account_block{
  float: right;
  position: relative;
  margin-right: 40px;
  height: 100%;
  text-align: left;
  line-height: 85px;
  .avatar{
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-top: 20px;
    border-radius:50%;
    overflow: hidden;
    margin-right: 10px;
  }
  span{
    vertical-align: top;
  }

  .hover_show_name{
    display: none;
  }

  .drop_menu{
    position: absolute;
    width: 220px;
    padding:3px 0;
    border:1px solid rgba(233,233,233,1);
    border-radius:3px;
    right:0;
    top:80px;
    line-height: 25px;
    font-size: 16px;

    transition:all 0.3s;
    color:#939393;
    box-shadow:3px 2px 4px 0px rgba(90,90,90,0.21);
    z-index: 3000;
    .company_box{
      border-bottom: 1px solid #E9E9E9;
      padding-left: 20px;
      overflow: hidden;
      .p1{
        margin-top: 14px;
        padding-right: 20px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: all 1.5s;
        // &:hover{
        //   text-indent:-50px;
        // }
      }

      .p1,.p2{
        line-height: 1!important;
        margin-bottom: 10px;
      }

    }
    ul{
      list-style: none;
    }
    li{
      width:100%;
      padding:6px 20px;
    }
    li:hover{
      color:#666666;
      background-color: aliceblue;
      cursor: pointer;
    }
    .quit_btn{
      width: 180px;
      margin:15px 0;
      margin-left: 20px;
      background: #F4FBFE;
      border:none;
      border-radius: 20px;
      transition: all 0.3s;
      font-size: 16px;
      // &:hover{
      //   box-shadow: 0px 0px 3px #e8e8ff,-0px -0px 3px #e8e8ff;
      // }
    }
  }


}
</style>
